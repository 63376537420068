.fade-in-text {
  opacity: 0;
  animation: fadeIn 2s forwards;
  color: white;
  mix-blend-mode: lighten;
  font-family: 'Indie Flower', cursive;
  font-size: 1.3vw;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px; /* Added space in between rows */
}

.fade-out-text {
  opacity: 0.9;
  animation: fadeOut 0.5s forwards;
  color: white;
  mix-blend-mode: darken;
  font-size: 1.3vw;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  to {
    opacity: 0.9;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}