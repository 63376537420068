body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}


h1, h2, h3 {
  color: #333;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  font-size: 2em;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

p {
  margin: 10px 0;
  color: #666;
}

ul {
  margin: 10px 0;
  padding: 0 20px;
  color: #666;
}

li {
  margin: 5px 0;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}