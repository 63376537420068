.video-player {
  height: auto;
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  objectFit: 'contain';
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .video-container {
    display: none;
  }
}

.main-menu-container {
  float: left;
  width: 75%;
  height: auto;
  position: absolute;
  top: 200;
  left: 400;
  width: 100%;
}