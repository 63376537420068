*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
}

body {
	margin: 0;
	--color-text: #fff;
	--color-bg: #c2c6c9;
	--color-link: #000;
	--color-link-hover: #000;
	--color-menu: #ccc;
	--fontsize-menu: 16px;

	color: var(--color-text);
	background-color: var(--color-bg);
  --font-menu: 'Indie Flower', cursive;
  font-family: 'Indie Flower', cursive;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.demo-2 {
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #ddd;
	--color-link-hover: #fff;
	--color-menu: #fff;
	--font-menu: hothouse, sans-serif;
	--fontsize-menu: 10px;
}

.demo-3 {
	--color-text: #fff;
	--color-bg: #e2c6cc;
	--color-link: #000;
	--color-link-hover: #fff;
	--color-menu: #000;
	--font-menu: laca-text, sans-serif;
	--fontsize-menu: 18px;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
    content: '';
    position: fixed;
    z-index: 1000;
}

.js .loading::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.js .loading::after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border-radius: 50%;
    opacity: 0.4;
    background: var(--color-link);
    animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}

.hidden {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	z-index: -1;
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	border-bottom: 2px solid;
	transition: border-color 0.1s;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: none;
	border-color: transparent;
}

main {
	padding: 3rem 5vw;
	position: relative;
	z-index: 1000;
}

.title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: normal;
}

.links {
	display: inline;
}

.demos {
	margin: 1rem 0;
}

.demo--current,
.demo--current:hover {
	border-color: transparent;
}

.text--menu {
	border: none;
	color: var(--color-link);
	margin: 1rem 0;
}

.credits a {
	color: currentColor;
}

.menu {
	width: 100%;
	min-height: 250px;
	display: grid;
}

.menu__item {
    border: none;
    transition: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 1.5rem;
}

.menu__text {
	font-size: var(--fontsize-menu);
	fill: var(--color-menu);
    display: block;
    font-family: var(--font-menu);
	font-weight: 700;
    position: absolute;
    height: 120%;
    width: 100%;
    pointer-events: none;
}

.menu__text text {
	transform-origin: 50% 50%;
	transform-box: view-box;
}

.menu__text text:nth-child(2) { 
	opacity: 0;
}

@media screen and (min-width: 53em) {
	main {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		justify-items: start;
		width: 100%;
		height: 100vh;
		padding: 2rem 3rem;
		grid-template-columns: 30% 30% 40%;
		grid-template-rows: auto 10vh auto 2fr 5rem;
		grid-template-areas: 'title text-menu text-subscribe'
							'... ... ...'
							'links menu menu'
							'demos menu menu'
							'credits ... ...';
	}
	.title {
		grid-area: title;
		margin: 0;
	}
	.demos {
		margin: 0;
		grid-area: demos;
		display: flex;
		flex-direction: column;
	}
	.demo, .links a {
		margin-bottom: 0.5rem;
	}
	.links {
		grid-area: links;
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;
		padding-top: 0.5rem;
	}
	.credits {
		margin: 0;
		grid-area: credits;
		align-self: end;
	}
	.text--menu {
		grid-area: text-menu;
		margin: 0;
	}
	.text--subscribe {
		grid-area: text-subscribe;
		justify-self: end;
	}
	.menu {
		grid-area: menu;
	}
}
